import { query } from '@solidjs/router';
import { isServer } from 'solid-js/web';
import { getConfigValue } from '../../../modules/config';
import { ttlCache } from './cache';
import { getEntriesServer, getEntryServer } from './_data';
import type { contentful, ContentfulModel } from '@troon/contentful';
import type { CachedFunction } from '@solidjs/router';

export function createContentfulRequest<
	M extends ContentfulModel,
	R extends ReturnType<(typeof contentful)[M]['get']> = ReturnType<(typeof contentful)[M]['get']>,
	O extends Parameters<(typeof contentful)[M]['get']>[1] = Parameters<(typeof contentful)[M]['get']>[1],
>(model: M, opts?: O): CachedFunction<(id: string, inlineOpts?: O) => Promise<R>> {
	return query(async (id: string, inlineOpts?: O): Promise<R> => {
		const allOpts = { ...(opts ?? {}), ...(inlineOpts ?? {}) };
		if (isServer) {
			return getEntryServer(model, id, allOpts) as Promise<R>;
		}

		const params = new URLSearchParams();
		for (const [key, val] of Object.entries(allOpts)) {
			params.set(key, `${val}`);
		}

		const key = `${model}/${id}?${params.toString()}`;
		const cached = ttlCache.get(key);
		if (cached) {
			return Promise.resolve(cached as R);
		}

		const res = await fetch(`https://${getConfigValue('HOST')}/content/${key}`);
		if (!res.ok) {
			throw new Error('Unable to load content');
		}
		const data = (await res.json()) as Promise<R>;
		if (!params.has('preview')) {
			ttlCache.set(key, data);
		}
		return data;
	}, `contentful-${model}`);
}

export function createContentfulListRequest<
	M extends ContentfulModel,
	R extends ReturnType<(typeof contentful)[M]['list']> = ReturnType<(typeof contentful)[M]['list']>,
	O extends Parameters<(typeof contentful)[M]['list']>[0] = Parameters<(typeof contentful)[M]['list']>[0],
>(model: M, opts?: O): CachedFunction<(pagination?: O) => Promise<R>> {
	return query(async (inlineOpts?: O) => {
		if (isServer) {
			return getEntriesServer(model, { ...opts, ...inlineOpts }) as Promise<R>;
		}

		const params = new URLSearchParams();
		for (const [key, val] of Object.entries({ ...opts, ...inlineOpts })) {
			params.set(key, `${val}`);
		}

		const key = `${model}?${params.toString()}`;
		const cached = ttlCache.get(key);
		if (cached) {
			return Promise.resolve(cached as R);
		}

		const res = await fetch(`https://${getConfigValue('HOST')}/content/${key}`);
		if (!res.ok) {
			throw new Error('Unable to load content');
		}
		const data = (await res.json()) as Promise<R>;
		ttlCache.set(key, data);
		return data;
	}, `contentful-list-${model}`);
}
